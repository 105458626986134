<template>
    <maintenance-page
        page-title="Bill Import"
        page-icon="mdi-account-multiple-outline"
        collection-name="billImport"
        done-redirect-url="/auth/admin/billimport?isHistory=true"
        :buttonFunctions="{
            interpretBill,
            reinterpretBill,
        }"
        :buttonsLeftItems="buttonsLeftItems"
        :reloadTrigger="saveCounter"
        @input="formUpdated"
    >
        <template v-slot:after-form>
            <div v-if="billData">
                <bill-canvas
                    :pages="billData.pages"
                    :page-urls="pageUrls"
                    @text-block-selected="textBlockSelected"
                    :bill-profile-text-block="billProfileTextBlock"
                    :bill-definition-text-block="billDefinitionTextBlock"
                    :bill-vendor-text-block="billVendorTextBlock"
                    :bill-private-note-text-block="billPrivateNoteTextBlock"
                    :bill-invoice-note-text-block="billInvoiceNoteTextBlock"
                    :data-fields="dataFields"
                    :saveCounter="saveCounter"
                />
            </div>
        </template>
    </maintenance-page>
</template>

<script>
import MaintenancePage from '@/components/pages/Maintenance';
import BillCanvas from '@/views/admin/maintenance/bill/BillCanvas.vue';

import api from '@/api';

const buttonsLeftItems = [
    {
        id: 'interpretBill',
        preset: 'edit',
        label: 'Interpret Bill',
        size: 'small',
        clickFunctionName: 'interpretBill',
    },
    {
        id: 'reinterpretBill',
        preset: 'edit',
        label: 'Re-Interpret Bill',
        size: 'small',
        clickFunctionName: 'reinterpretBill',
    },
];

export default {
    name: 'BillImportDetail',
    components: { MaintenancePage, BillCanvas },
    data: () => ({
        id: null,
        buttonsLeftItems,
        billData: null,
        fields: [],
        fieldNames: [],
        pageUrls: [],
        billProfileTextBlock: null,
        billDefinitionTextBlock: null,
        billVendorTextBlock: null,
        billPrivateNoteTextBlock: null,
        billInvoiceNoteTextBlock: null,
        dataFields: [],
        formModel: null,
        credentialClientConfig: null,
        selectedTextArea: null,
        saveCounter: 0,
    }),
    methods: {
        async formUpdated(formModel) {
            if (formModel && this.formModel) {
                console.log('formUpdated', formModel);
                console.log('masterfile changed?', this.formModel.masterFile !== formModel.masterFile);
                if (this.formModel.masterFile !== formModel.masterFile) {
                    await this.interpretBill(formModel);
                }
            }
            this.formModel = formModel;
        },
        log(formModel) {
            console.log('form updated', formModel);
        },
        processResultData(result) {
            console.log('processResult ', result);
            if (result.data && result.data.billImport) {
                const { billImport, pageUrls } = result.data;
                console.log('billPrivateNoteTextBlock', billImport.billPrivateNoteTextBlock);
                this.pageUrls = pageUrls;
                this.billData = billImport.billData;
                this.fields = billImport.fields;
                this.billProfileTextBlock = billImport.billProfileTextBlock;
                this.billDefinitionTextBlock = billImport.billDefinitionTextBlock;
                this.billVendorTextBlock = billImport.billVendorTextBlock;
                this.billPrivateNoteTextBlock = billImport.billPrivateNoteTextBlock;
                this.billInvoiceNoteTextBlock = billImport.billInvoiceNoteTextBlock;
                console.log('loaded bprofid', this.billProfileTextBlock);
                if (!this.billData) {
                    this.fieldNames = [];
                    this.dataFields = [];
                } else {
                    this.fieldNames = Object.keys(this.fields);
                    this.dataFields = this.fieldNames.map((oneFieldName) => {
                        const field = this.fields[oneFieldName];
                        const { vertices, pageIndex, rawValue: text } = field;
                        console.log('field', oneFieldName, field);
                        return { fieldName: oneFieldName, vertices, pageIndex, text };
                    });
                }
            }
            this.saveCounter++;
        },
        async interpretBill(formModel) {
            console.log('interpretBill formModel', formModel);
            await this.$store.dispatch('setLoading', 'Interpreting bill data');
            const result = await api.post(this.$store, `billimport/interpretbill`, { id: this.id });

            this.processResultData(result);
            await this.$store.dispatch('setLoading');
        },
        async reinterpretBill(formModel) {
            console.log('interpretBill formModel', formModel);
            await this.$store.dispatch('setLoading', 'Interpreting bill data');
            const result = await api.post(this.$store, `billimport/interpretbill`, { id: this.id, isSkipProcessing: true });

            this.processResultData(result);
            await this.$store.dispatch('setLoading');
        },
        textBlockSelected(selectedTextArea) {
            console.log('textBlockSelected', selectedTextArea);
            this.selectedTextArea = selectedTextArea;
        },
        async loadData() {
            console.log('src/views/admin/maintenance/bill/billImport/Detail.vue loadData 1');
            const result = await api.get(this.$store, `billimport/${this.id}?isLoadPageUrls=true`);
            console.log('src/views/admin/maintenance/bill/billImport/Detail.vue loadData 2');
            this.processResultData(result);
            console.log('src/views/admin/maintenance/bill/billImport/Detail.vue loadData 3');
        },
        async init() {
            console.log('init');
            await this.loadData();
        },
    },
    watch: {
        saveCounter() {
            console.log('saveCounter ----> ', this.saveCounter);
        },
    },
    async mounted() {
        console.log('src/views/admin/maintenance/bill/billImport/Detail.vue mounted 1');
        this.id = this.$route.params.id;
        console.log('src/views/admin/maintenance/bill/billImport/Detail.vue mounted 2');

        await this.init();
        console.log('src/views/admin/maintenance/bill/billImport/Detail.vue mounted 3');
    },
};
</script>
